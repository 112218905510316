import { getCountryId } from "../Helpers/user.helper";
import { loginHttpHelper } from "./HttpServiceUtils/HttpHelper";
import { loggerhelper } from "./LoggerService/LoggerHelper";
import { PageNames } from './LoggerService/PageNames';

export const getAllFiscalYears = async () => {
    try {
        loggerhelper.info(PageNames.COMMONSERVICE, `Get AllfiscalYear`, `from Common Service`);
        const response = await loginHttpHelper.get(`/fiscalyear/country/${getCountryId() && getCountryId()}`,true);
        const allFiscalYears = response.data;

        loggerhelper.trace(PageNames.COMMONSERVICE, `Get AllfiscalYear`, `from Common Service`);
        return { allFiscalYears };
    } catch (err) {
        loggerhelper.error(err)
    }

};

export const getCurrentFiscalYears = async (month, year) => {
    try {
        loggerhelper.info(PageNames.COMMONSERVICE, `Get CurrentFiscalYears`, `from Common Service`);
        const response = await loginHttpHelper.get(`/fiscalyear/${month}/${year}/country/${getCountryId() && getCountryId()}`);
        const CurrentFiscalYears = response;
        loggerhelper.trace(PageNames.COMMONSERVICE, `Get CurrentFiscalYears`, `from Common Service`);
        return { CurrentFiscalYears };
    } catch (err) {
        loggerhelper.error(err)
    }
};

export const getCountries = async () => {
    try {
        loggerhelper.trace(PageNames.COMMONSERVICE, `Get Countries`, `from Common Service`);
        const response = await loginHttpHelper.get('/Country');
        loggerhelper.trace(PageNames.COMMONSERVICE, `Get Countries`, `from Common Service`);
        return { response };
    } catch (err) {
        loggerhelper.error(err)
    }

};

export const getYearEndFiscalYear = async (fiscalYearID) => {
    try {
        loggerhelper.info(PageNames.COMMONSERVICE, `Get YearEndFiscalYears`, `from Common Service`);
        const response = await loginHttpHelper.get(`/fiscalyear/${fiscalYearID}`);
        loggerhelper.trace(PageNames.COMMONSERVICE, `Get YearEndFiscalYears`, `from Common Service`);
        return { response };
    } catch (err) {
        loggerhelper.error(err)
    }
};

