import { IconNames } from "@nws/nws-ui-library";
import { checkActionPermision } from "../../../../Helpers/helper";
import {
  homeDashBoardCardTypes,
  homeDashboardCardClientTypes,
  homeDashboardCardMenuId,
} from "./Constants";
import { ClientDashboardV2Keys } from "../../../../Context/ClientStateContext";

export const clientDashBoardColumns = (t) => [
  {
    title: t("commonLabels.clientName"),
    dataIndex: "clientName",
    width: "40%",
  },
  {
    title: t("commonLabels.comments"),
    dataIndex: "comments",
    width: "40%",
  },
  {
    title: t("commonLabels.fisicalYear"),
    dataIndex: "fiscalYear",
    width: "10%",
  },
];

const clientProcedureItemsPath =
  "FinancialAuditing/Clients/NewClientDashboard/ClientProcedures/ClientProcedureItems/";

const clientProceduresScreen = (t) => [
  {
    id: 1,
    title: t("accountingStandards.accountingStandards"),
    tabTitle: t("menuItem.accountingStandards"),
    tabPath:
      clientProcedureItemsPath + "AccountingStandards/AccountingStandards",
    clientStateKey: ClientDashboardV2Keys.AccountingStandard,
    menuId: 63,
    uniqueKey: "AccountingStandards and Applicability",
  },
  {
    id: 2,
    title: t("profitAndLoss.profitAndLossLabel"),
    tabTitle: t("menuItem.profitAndLoss"),
    tabPath: clientProcedureItemsPath + "ProfitAndLoss/ProfitAndLoss",
    clientStateKey: ClientDashboardV2Keys.ProfitAndLoss,
    menuId: 64,
    uniqueKey: "ProfitAndLoss",
  },
  {
    id: 3,
    title: t("balanceSheet.balanceSheetLabel"),
    tabTitle: t("menuItem.balanceSheet"),
    tabPath: clientProcedureItemsPath + "BalanceSheet/BalanceSheet",
    clientStateKey: ClientDashboardV2Keys.BalanceSheet,
    menuId: 65,
    uniqueKey: "BalanceSheet",
  },
  {
    id: 4,
    title: t("goingConcern.goingConcern"),
    tabTitle: t("menuItem.goingConcern"),
    tabPath: clientProcedureItemsPath + "GoingConcern/GoingConcern",
    clientStateKey: ClientDashboardV2Keys.GoingConcernChecklist,
    menuId: 66,
    uniqueKey: "GoingConcern Review",
  },
  {
    id: 5,
    title: t("auditSignOff.auditSignOff"),
    tabTitle: t("menuItem.auditSignOff"),
    tabPath: clientProcedureItemsPath + "AuditSignOff/AuditSignOff",
    clientStateKey: ClientDashboardV2Keys.AuditSignOffChecklist,
    menuId: 67,
    uniqueKey: "AuditSignOff",
  },
];

export const dashBoardCardData = (t, menuListArr, accessView) => [
  {
    id: 1,
    uniqueKey: "home-card-1",
    iconName: IconNames.IconEngagements,
    displayLabel: t("commonLabels.engagementsLabel"),
    type: homeDashBoardCardTypes.Engagements,
    menuId: homeDashboardCardMenuId.Engagements,
    hasScreenPermission: false,
    shouldShow: checkActionPermision(
      menuListArr,
      homeDashboardCardMenuId.Engagements,
      accessView,
      true
    ),
    children: [
      {
        id: 2,
        uniqueKey: "home-card-1-1",
        displayLabel: t("dashBoardCards.engagementsCard.activeClientLabel"),
        type: homeDashboardCardClientTypes.ActiveClients,
        hasBorder: true,
        hasScreenPermission: false,
      },
      {
        id: 3,
        uniqueKey: "home-card-1-2",
        displayLabel: t("dashBoardCards.engagementsCard.newEngagementLabel"),
        type: homeDashboardCardClientTypes.NewEngegements,
        hasBorder: true,
        hasScreenPermission: false,
      },
      {
        id: 4,
        uniqueKey: "home-card-1-3",
        displayLabel: t(
          "dashBoardCards.engagementsCard.engagementContinuousLabel"
        ),
        type: homeDashboardCardClientTypes.EngagementContinuous,
        hasBorder: false,
        hasScreenPermission: false,
      },
    ],
  },
  {
    id: 5,
    uniqueKey: "home-card-2",
    iconName: IconNames.CurrentYearPlanning,
    displayLabel: t(
      "dashBoardCards.clientDetailsCard.currentYearPlanningLabel"
    ),
    type: homeDashBoardCardTypes.CurrentYearPlanning,
    menuId: homeDashboardCardMenuId.CurrentYearPlanning,
    shouldShow: checkActionPermision(
      menuListArr,
      homeDashboardCardMenuId.CurrentYearPlanning,
      accessView,
      true
    ),
    hasScreenPermission: false,
  },
  {
    id: 6,
    uniqueKey: "home-card-3",
    iconName: IconNames.IconPlanningCompleted,
    displayLabel: t("dashBoardCards.clientDetailsCard.planningCompletedLabel"),
    type: homeDashBoardCardTypes.PlanningCompleted,
    menuId: homeDashboardCardMenuId.PlanningCompleted,
    shouldShow: checkActionPermision(
      menuListArr,
      homeDashboardCardMenuId.PlanningCompleted,
      accessView,
      true
    ),
    hasScreenPermission: false,
  },
  {
    id: 7,
    uniqueKey: "home-card-4",
    iconName: IconNames.IconProcedureInProgress,
    displayLabel: t(
      "dashBoardCards.clientDetailsCard.procedureInProgressLabel"
    ),
    type: homeDashBoardCardTypes.ProcedureInProgress,
    menuId: homeDashboardCardMenuId.ProcedureInProgress,
    shouldShow: checkActionPermision(
      menuListArr,
      homeDashboardCardMenuId.ProcedureInProgress,
      accessView,
      true
    ),
    hasScreenPermission: false,
  },
  {
    id: 8,
    uniqueKey: "home-card-5",
    iconName: IconNames.IconProcedureCompleted,
    displayLabel: t("dashBoardCards.clientDetailsCard.procedureCompletedLabel"),
    type: homeDashBoardCardTypes.ProcedureCompleted,
    menuId: homeDashboardCardMenuId.ProcedureCompleted,
    shouldShow: checkActionPermision(
      menuListArr,
      homeDashboardCardMenuId.ProcedureCompleted,
      accessView,
      true
    ),
    hasScreenPermission: false,
  },
  {
    id: 9,
    uniqueKey: "home-card-6",
    iconName: IconNames.IconProcedureInProgress,
    displayLabel: t("dashBoardCards.clientDetailsCard.procedureReviewPending"),
    type: homeDashBoardCardTypes.ProcedureReviewPending,
    shouldShow: true,
    hasScreenPermission: true,
    screenData: clientProceduresScreen(t),
  },
  {
    id: 10,
    uniqueKey: "home-card-7",
    iconName: IconNames.IconProcedureCompleted,
    displayLabel: t("dashBoardCards.clientDetailsCard.procedureApprovePending"),
    type: homeDashBoardCardTypes.ProcedureApprovePending,
    shouldShow: true,
    hasScreenPermission: true,
    screenData: clientProceduresScreen(t),
  },
];
