import React from "react";
import { HeaderBar } from "../../components/Shared/Header/HeaderBar";
import { LHSMenuPanel } from "../../components/Menu/LHSMenuPanel";
import LandingContainer from "../../components/LandingPage/LandingContainer";
import { LaodingSpinner } from "../../components/Shared/LoadingSpinner";
import { ReloginPopup } from "../../components/Shared/Relogin/ReloginPopup";
import { ClientContextProvider } from "../../Context/ClientContextContext";
import { FinalCompletionContextProvider } from "../../Context/FinalCompletionContext";
import { ClientDashboardContextProvider } from "../../Context/CommonDataContext";
import { ClientStateContextProvider } from "../../Context/ClientStateContext";
import { useEffect } from "react";
import { useContext } from "react";
import {
  GlobalStateContext,
  GlobalStateKeys,
} from "../../Context/GlobalStateContext";
import { getCountryId, getUserCompanyId } from "../../Helpers/user.helper";
import {
  getAllFiscalYears,
  getCurrentFiscalYears,
} from "../../Services/Genaral.Services";
import { selectActiveClients } from "../../Services/FAEngagement.Service";
import { useStore } from "../../store";
import { getMenuList } from "../../Services/Settings.Services";
import { permissionWrapper } from "../../Helpers/helper";
import { getAllFeatureFlagStatus } from "../../Services/TaskManagementService/Task.Services";

export default function LandingPage(props) {
  const { setGlobalStateMany } = useContext(GlobalStateContext);
  const getMonth = new Date().getMonth() + 1;
  const getYear = new Date().getFullYear();
  const [setmenuListArr, menuListArr] = useStore((state) => [
    state.setmenuListArr,
    state.menuListArr,
  ]);

  useEffect(() => {
    getCountryId() && fetchActiveClientsAndFiscalYears();
    getMenuList().then((menuList) => {
      setmenuListArr(menuList);
      permissionWrapper.setMenuList(menuList);
    });
  }, []);

  const fetchActiveClientsAndFiscalYears = async () => {
    try {
      const { allFiscalYears } = await getAllFiscalYears();
      const { response } = await selectActiveClients();
      const { CurrentFiscalYears } = await getCurrentFiscalYears(
        getMonth,
        getYear
      );

      Promise.resolve(getAllFeatureFlagStatus(getUserCompanyId()))
        .then(({ allFeatureFlags }) => {
          setGlobalStateMany(
            [
              GlobalStateKeys.ClientFiscalYears,
              GlobalStateKeys.FeatureFlagDetails,
            ],
            [
              {
                activeClients: response.data,
                fiscalYears: allFiscalYears,
                currentFiscalYear: CurrentFiscalYears?.data,
              },
              {
                featureFlags: allFeatureFlags.data,
              },
            ]
          );
        })
        .catch((error) => {
          console.error("Failed to fetch feature flags:", error);
        });
    } catch (error) {
      console.error("Failed to fetch active clients and fiscal years:", error);
    }
  };

  return (
    <ClientStateContextProvider>
      <ClientContextProvider>
        <ClientDashboardContextProvider>
          <FinalCompletionContextProvider>
            <main>
              <LaodingSpinner />
              <section className="nw-app-outer-wrapper">
                <div className="nw-app-outer">
                  <HeaderBar />
                  <div className="nw-app-body-wrapper">
                    <aside
                      className="nw-app-main-aside"
                      style={{ overflow: "auto", scrollbarWidth: "thin" }}
                    >
                      <nav className="nw-main-nav--wrapper">
                        <LHSMenuPanel />
                      </nav>
                    </aside>
                    <main className="nw-app-core-content-wrapper">
                      <LandingContainer />
                    </main>
                  </div>
                </div>
              </section>
              <div className="nw-app-chat-icon">
                <img
                  className="sv-icon ico-mag-glass"
                  src={"Images/Icon-material-chat.svg"}
                  width="200"
                  alt="Networth Logo"
                />
              </div>
              <ReloginPopup />
            </main>
          </FinalCompletionContextProvider>
        </ClientDashboardContextProvider>
      </ClientContextProvider>
    </ClientStateContextProvider>
  );
}
