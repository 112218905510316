import { getCountryId } from "../Helpers/user.helper";
import httpHelper, { loginHttpHelper } from "./HttpServiceUtils/HttpHelper";
import { loggerhelper } from "./LoggerService/LoggerHelper";
import { PageNames } from './LoggerService/PageNames';

export const getEngagementSettingsForEngagementSave = async () => {
    try {
        loggerhelper.info(PageNames.ENGAGEMENT, `Get Engagementsettings detais`, `from FinancialAudit| Engagement `);
        const response = await loginHttpHelper.get(`/EngagementSettings/for-engagement/country/${getCountryId() && getCountryId()}`);

        loggerhelper.trace(PageNames.ENGAGEMENT, `Get Engagementsettings detais`, `from FinancialAudit| Engagement `);
        return { response };
    } catch (err) {
        loggerhelper.error(err);
    }

};

export const selectActiveClients = async () => {
    try {
        loggerhelper.info(PageNames.ENGAGEMENT, `Select Active Clients in Engagement Data`, `from FinancialAudit| Engagement `);
        const response = await httpHelper.get('/clients/active',true);

        loggerhelper.trace(PageNames.ENGAGEMENT, `Select Active Clients in Engagement Data`, `from FinancialAudit| Engagement `);
        return { response };
    } catch (err) {
        loggerhelper.error(err);
    }



};

export const getEngagement = async (z_EngClintID) => {
    try {
        loggerhelper.info(PageNames.ENGAGEMENT, `Get Engagement Data`, `from FinancialAudit| Engagement `, `${z_EngClintID}`);
        const response = await httpHelper.get('/EngagementHdr/' + z_EngClintID);
        loggerhelper.trace(PageNames.ENGAGEMENT, `Get Engagement Data`, `from FinancialAudit| Engagement `, `${z_EngClintID}`);

        return { response };
    } catch (err) {
        loggerhelper.error(err);
    }

};


export const addAndUpdatengagement = async (id, data) => {
    try {
        loggerhelper.info(PageNames.ENGAGEMENT, `Add and Update Engagement Data`, `from FinancialAudit| Engagement `, `${id, data}`);
        if (id == "") {
            const response = await httpHelper.post('/EngagementHdr', data);
            const result = response;
            return { result };
        } else {
            const response = await httpHelper.put('/EngagementHdr', data);
            const result = response;
            loggerhelper.trace(PageNames.ENGAGEMENT, `Add and Update Engagement Data`, `from FinancialAudit| Engagement `, `${id, data}`);
            return { result };
        }
    } catch (err) {
        loggerhelper.error(err);
    }


};

export const approveEngagement = async (data) => {
    try {
        const response = await httpHelper.put('/EngagementHdr/approval', data);
        return { response };
    } catch (err) {
        loggerhelper.error(err);
    }

};
